import BigNumber from 'bignumber.js';
import balleMasterABI from 'config/abi/balleMaster.json';
import erc20 from 'config/abi/erc20.json';
import strat from 'config/abi/strat.json';
import vaultsConfig from 'config/constants/vaults';
import { VaultStateEnum } from 'state/types';
import { getAddress, getBalleMasterAddress } from 'utils/addressHelpers';
import { BIG_ZERO } from 'utils/bigNumber';
import { getVaultFarmData } from 'utils/farms';
import multicall from 'utils/multicall';
import web3NoAccount from 'utils/web3';
import { AbiItem } from 'web3-utils';

const fetchVaults = async () => {
  const data = await Promise.all(
    vaultsConfig.map(async (vaultConfig) => {
      const depositTokenAddress = getAddress(vaultConfig.depositTokenAddresses);
      const stratAddress = getAddress(vaultConfig.stratAddresses);
      let depositTokenVaultBalance = 0;
      let tokenAmount = BIG_ZERO;
      let quoteTokenAmount = BIG_ZERO;
      let lpTotalInQuoteToken = BIG_ZERO;
      let farmTotalInQuoteToken = BIG_ZERO;

      const { farmPoolWeight, farmRewardPerBlock } = await getVaultFarmData(vaultConfig);

      if (vaultConfig.depositType === 'LP') {
        const calls = [
          // Balance of token in the LP contract
          {
            address: getAddress(vaultConfig.token.address),
            name: 'balanceOf',
            params: [depositTokenAddress],
          },
          // Balance of quote token on LP contract
          {
            address: getAddress(vaultConfig.quoteToken.address),
            name: 'balanceOf',
            params: [depositTokenAddress],
          },
          // Total supply of LP tokens
          {
            address: depositTokenAddress,
            name: 'totalSupply',
          },
          // Token decimals
          {
            address: getAddress(vaultConfig.token.address),
            name: 'decimals',
          },
          // Quote token decimals
          {
            address: getAddress(vaultConfig.quoteToken.address),
            name: 'decimals',
          },
        ];

        const [tokenBalanceLP, quoteTokenBalanceLP, lpTotalSupply, tokenDecimals, quoteTokenDecimals] = await multicall(
          erc20,
          calls,
        );

        const stratContract = new web3NoAccount.eth.Contract((strat as unknown) as AbiItem, stratAddress);
        depositTokenVaultBalance = await stratContract.methods.depositTotal().call();

        // Ratio of LP tokens that are in the vault, vs the total number in circulation
        const lpTokenVaultRatio = new BigNumber(depositTokenVaultBalance).div(new BigNumber(lpTotalSupply));

        // Total value in the vault in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(2))
          .times(lpTokenVaultRatio);

        // Amount of token in the LP that are considered in the vault (i.e amount of token * lp vault ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenVaultRatio);

        // Amount of quote token in the LP that are considered in the vault (i.e amount of quote token * lp vault ratio)
        quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenVaultRatio);

        // Total value in the farm's pool in quote token value
        farmTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(2));
      } else {
        // singleAsset

        const calls = [
          // Balance of token in the farm contract
          {
            address: getAddress(vaultConfig.token.address),
            name: 'balanceOf',
            params: [getAddress(vaultConfig.farmAddresses)],
          },
          // Token decimals
          {
            address: getAddress(vaultConfig.token.address),
            name: 'decimals',
          },
          // Quote token decimals
          {
            address: getAddress(vaultConfig.quoteToken.address),
            name: 'decimals',
          },
        ];

        const [farmDepositTokenBalance, tokenDecimals, quoteTokenDecimals] = await multicall(erc20, calls);

        const stratContract = new web3NoAccount.eth.Contract((strat as unknown) as AbiItem, stratAddress);
        depositTokenVaultBalance = await stratContract.methods.depositTotal().call();

        // Amount of token in the vault
        tokenAmount = new BigNumber(depositTokenVaultBalance).div(new BigNumber(10).pow(tokenDecimals));

        // Amount of quote token in the vault (it's a single asset, so, the same as above)
        quoteTokenAmount = new BigNumber(depositTokenVaultBalance).div(new BigNumber(10).pow(quoteTokenDecimals));

        // We set lpTotalInQuoteToken even when not a LP vault because it's used for TVL calculation
        // maybe that should be changed? getVaultTvl() on src/hooks/useTvl.ts
        lpTotalInQuoteToken = new BigNumber(depositTokenVaultBalance).div(new BigNumber(10).pow(quoteTokenDecimals));

        // Total value in the farm's pool in quote token value
        farmTotalInQuoteToken = new BigNumber(farmDepositTokenBalance).div(new BigNumber(10).pow(quoteTokenDecimals));
      }

      const [info, totalAllocPoint] = await multicall(balleMasterABI, [
        {
          address: getBalleMasterAddress(vaultConfig.migration),
          name: 'vaultInfo',
          params: [vaultConfig.vid],
        },
        {
          address: getBalleMasterAddress(vaultConfig.migration),
          name: 'totalAllocPoint',
        },
      ]);

      const allocPoint = new BigNumber(info.allocPoint._hex);
      const vaultWeight =
        allocPoint.toNumber() === 0 ? new BigNumber(0) : allocPoint.div(new BigNumber(totalAllocPoint));
      /* eslint-disable no-nested-ternary */
      const vaultState =
        info.retired === true
          ? VaultStateEnum.RETIRED
          : info.lastRewardBlock.toNumber() === 0
          ? VaultStateEnum.NEW
          : info.paused === true
          ? VaultStateEnum.PAUSED
          : VaultStateEnum.ACTIVE;

      return {
        ...vaultConfig,
        depositTokenAmount: depositTokenVaultBalance,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        vaultWeight: vaultWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        farmPoolWeight: farmPoolWeight.toJSON(),
        farmRewardPerBlock: farmRewardPerBlock.toJSON(),
        farmTotalInQuoteToken: farmTotalInQuoteToken.toJSON(),
        vaultState,
      };
    }),
  );
  return data;
};

export default fetchVaults;
