import BigNumber from 'bignumber.js';
import balleMasterABI from 'config/abi/balleMaster.json';
import erc20ABI from 'config/abi/erc20.json';
import vaultsConfig from 'config/constants/vaults';
import { getAddress, getBalleMasterAddress } from 'utils/addressHelpers';
import multicall from 'utils/multicall';

export const fetchVaultUserAllowances = async (account: string) => {
  const balleMasterAddress = getBalleMasterAddress(false);
  const balleMasterMigAddress = getBalleMasterAddress(true);

  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = getAddress(vault.depositTokenAddresses);
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, vault.migration ? balleMasterMigAddress : balleMasterAddress],
    };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls);
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON();
  });
  return parsedLpAllowances;
};

export const fetchVaultUserTokenBalances = async (account: string) => {
  const balleMasterAddress = getBalleMasterAddress(false);
  const balleMasterMigAddress = getBalleMasterAddress(true);

  const calls = vaultsConfig.map((vault) => {
    return {
      address: vault.migration ? balleMasterMigAddress : balleMasterAddress,
      name: 'depositTokens',
      params: [vault.vid, account],
    };
  });

  const rawTokenBalances = await multicall(balleMasterABI, calls);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchVaultUserDepositedBalances = async (account: string) => {
  const balleMasterAddress = getBalleMasterAddress(false);
  const balleMasterMigAddress = getBalleMasterAddress(true);

  const calls = vaultsConfig.map((vault) => {
    return {
      address: vault.migration ? balleMasterMigAddress : balleMasterAddress,
      name: 'userInfo',
      params: [vault.vid, account],
    };
  });

  const rawDepositedBalances = await multicall(balleMasterABI, calls);
  const parsedDepositedBalances = rawDepositedBalances.map((depositedBalance) => {
    return new BigNumber(depositedBalance[0]._hex).toJSON();
  });
  return parsedDepositedBalances;
};

export const fetchVaultUserRewards = async (account: string) => {
  const balleMasterAddress = getBalleMasterAddress(false);
  const balleMasterMigAddress = getBalleMasterAddress(true);

  const calls = vaultsConfig.map((vault) => {
    return {
      address: vault.migration ? balleMasterMigAddress : balleMasterAddress,
      name: 'pendingRewards',
      params: [vault.vid, account],
    };
  });

  const rawRewards = await multicall(balleMasterABI, calls);
  const parsedRewards = rawRewards.map((rewards) => {
    return new BigNumber(rewards).toJSON();
  });
  return parsedRewards;
};

export const fetchUserWalletVaultTokenBalances = async (account: string) => {
  const calls = vaultsConfig.map((vault) => {
    const lpContractAddress = getAddress(vault.depositTokenAddresses);
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};
