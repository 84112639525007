import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/erc20.json';
import addresses from 'config/constants/contracts';
import tokens from 'config/constants/tokens';
import { getAddress } from 'utils/addressHelpers';
import multicall from 'utils/multicall';

const fetchBalleBusdPrice = async () => {
  const calls = [
    // Balance of BALLE on the LP contract
    {
      address: getAddress(tokens.balle.address),
      name: 'balanceOf',
      params: [getAddress(addresses.balleWbnbLP)],
    },
    // Balance of WBNB token on LP contract
    {
      address: getAddress(tokens.wbnb.address),
      name: 'balanceOf',
      params: [getAddress(addresses.balleWbnbLP)],
    },
    // Balance of WBNB on LP contract
    {
      address: getAddress(tokens.wbnb.address),
      name: 'balanceOf',
      params: [getAddress(addresses.wbnbBusdLP)],
    },
    // Balance of BUSD on LP contract
    {
      address: getAddress(tokens.busd.address),
      name: 'balanceOf',
      params: [getAddress(addresses.wbnbBusdLP)],
    },
  ];
  const [balleBalanceBalleLP, wbnbBalanceBalleLP, wbnbBalanceBusdLP, busdBalanceBusdLP] = await multicall(
    erc20ABI,
    calls,
  );

  const bnbPerBalle = new BigNumber(wbnbBalanceBalleLP).div(new BigNumber(balleBalanceBalleLP));
  const busdPerBnb = new BigNumber(busdBalanceBusdLP).div(new BigNumber(wbnbBalanceBusdLP));
  const balleBusdPrice = bnbPerBalle.times(busdPerBnb).toNumber();

  return balleBusdPrice;
};

export default fetchBalleBusdPrice;
