import tokens from './tokens';
import { VaultConfig } from './types';

const devVaultsConfig: VaultConfig[] = [
  {
    vid: 0,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TokenA-BNB',
    depositTokenAddresses: {
      97: '0x3edd0c7cec29179d45431471e413be197fb31446',
      56: '0x24EB18bA412701f278B172ef96697c4622b19da6',
    },
    migration: false,
    token: tokens.balbt,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 0,
    stratAddresses: {
      97: '0x3FE4818D6e8C28118A74BE4C1F2e81E9231e9750',
      56: '0x84a293347f9F3B1dA6bD061e67E857677F5410e5',
    },
  },
  {
    vid: 1,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TokenB-BNB',
    depositTokenAddresses: {
      97: '0x40a6f287fbf5e1841f76ce0218ff23edcd55f96d',
      56: '0x1bdcebca3b93af70b58c41272aea2231754b23ca',
    },
    migration: false,
    token: tokens.inj,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 1,
    stratAddresses: {
      97: '0xfC118c9CC085D49C1326C886932F5C2a208c590b',
      56: '0x437c97d695a822d10223059099dcca9559754e2d',
    },
  },
  {
    vid: 3,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'CAKE-BNB',
    depositTokenAddresses: {
      97: '0x8EB36F0272AC65B84b36Aa1980D5F3ec46b008b4',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    },
    migration: false,
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 4,
    stratAddresses: {
      97: '0x7D72DC5Cd7884Ea18bc81A13BaD5ef9e25a8d514',
      56: '0x5034919684b3bec9a4b0b134bef4b68660e36220',
    },
  },
  {
    vid: 4,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'BUSD-BNB',
    depositTokenAddresses: {
      97: '0xb51e4d3F60c8453AdCa52797F9FA1481A6E13A7A',
      56: '',
    },
    migration: false,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '',
    },
    farmPid: 4,
    stratAddresses: {
      97: '0x1B41CCAaAb0692C3E2C57865ed663D4116fB450C',
      56: '',
    },
  },
  {
    vid: 7,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'singleAsset',
    depositTokenSymbol: 'CAKE',
    depositTokenAddresses: {
      97: '0xa6d6240e5d5b6232996d58b6b3c4555c824dea73',
      56: '',
    },
    migration: false,
    token: tokens.cake,
    quoteToken: tokens.cake,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '',
    },
    farmPid: 0,
    stratAddresses: {
      97: '0x7c94bFeFd86480Ea51c00d34E02746F87D4907e5',
      56: '',
    },
  },
  {
    vid: 6,
    platform: 'ballena.io',
    type: 'balle',
    depositType: 'LP',
    depositTokenSymbol: 'BALLE-BNB',
    depositTokenAddresses: {
      97: '0x29089e9BdD77ac719Bcb94d6eE1ee34F2fEe8cf8',
      56: '0xa4C9418d4CE79cD2b5d64E08D91B7D00cEC40dC3',
    },
    migration: false,
    token: tokens.balle,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '',
    },
    farmPid: 0,
    stratAddresses: {
      97: '0x033105650F555D65108671Ee4367D7B0303fe407',
      56: '',
    },
  },
];

export default devVaultsConfig;
