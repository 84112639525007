import tokens from './tokens';
import { VaultConfig } from './types';
import devVaultsConfig from './vaults.dev';

const vaultsConfig: VaultConfig[] = [
  {
    vid: 0,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'bALBT-BNB',
    depositTokenAddresses: {
      97: '0x3edd0c7cec29179d45431471e413be197fb31446',
      56: '0x24EB18bA412701f278B172ef96697c4622b19da6',
    },
    migration: true,
    token: tokens.balbt,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 279,
    stratAddresses: {
      97: '0xD413Fb9E684bAFEE48e7BBA7A4F3868d4738A3eD',
      56: '0xDaAdA85E22DF14B12Fe3EDA2739b82541F206B2D',
    },
  },
  {
    vid: 1,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'INJ-BNB',
    depositTokenAddresses: {
      97: '0x40a6f287fbf5e1841f76ce0218ff23edcd55f96d',
      56: '0x1bdcebca3b93af70b58c41272aea2231754b23ca',
    },
    migration: true,
    token: tokens.inj,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 270,
    stratAddresses: {
      97: '0x1fF4570b91B6980C0e1Fc01951e35320d38ff7Eb',
      56: '0x8044182086ae7FEeb00CC40d45877188A5587025',
    },
  },
  {
    vid: 2,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'DOT-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    },
    migration: true,
    token: tokens.dot,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 255,
    stratAddresses: {
      97: '',
      56: '0x4da397f5C51bae9A37bC4ff6634b34b8e9111819',
    },
  },
  // New vaults
  {
    vid: 0,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'bALBT-BNB',
    depositTokenAddresses: {
      97: '0x3edd0c7cec29179d45431471e413be197fb31446',
      56: '0x24EB18bA412701f278B172ef96697c4622b19da6',
    },
    migration: false,
    token: tokens.balbt,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 279,
    stratAddresses: {
      97: '0x3FE4818D6e8C28118A74BE4C1F2e81E9231e9750',
      56: '0x84a293347f9F3B1dA6bD061e67E857677F5410e5',
    },
  },
  {
    vid: 1,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'INJ-BNB',
    depositTokenAddresses: {
      97: '0x40a6f287fbf5e1841f76ce0218ff23edcd55f96d',
      56: '0x1bdcebca3b93af70b58c41272aea2231754b23ca',
    },
    migration: false,
    token: tokens.inj,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '0x91E85122eF92FF306EA47025efd00404CcaEEb19',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 270,
    stratAddresses: {
      97: '0xfC118c9CC085D49C1326C886932F5C2a208c590b',
      56: '0x437c97d695a822d10223059099dcca9559754e2d',
    },
  },
  {
    vid: 2,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'DOT-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    },
    migration: false,
    token: tokens.dot,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 255,
    stratAddresses: {
      97: '',
      56: '0x5034919684b3bec9a4b0b134bef4b68660e36220',
    },
  },
  {
    vid: 3,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'VRT-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xEf5212aDa83EC2cc105C409DF10b8806D20E3b35',
    },
    migration: false,
    token: tokens.vrt,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 396,
    stratAddresses: {
      97: '',
      56: '0xd1D8A8D9F29920CE65E6F11526e76b1EBA485aA9',
    },
  },
  {
    vid: 4,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'BTCST-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xB2678C414ebC63c9CC6d1a0fC45f43E249B50fdE',
    },
    migration: false,
    token: tokens.btcst,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 285,
    stratAddresses: {
      97: '',
      56: '0x2eaf996c82851a0019fbf5295253b02c1cf397eb',
    },
  },
  {
    vid: 5,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'WATCH-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x13321AcfF4A27f3d2bcA64b8bEaC6e5FdAAAf12C',
    },
    migration: false,
    token: tokens.watch,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 312,
    stratAddresses: {
      97: '',
      56: '0xdf82c1825bc2bd7d82f739f8870b24c0e4663cce',
    },
  },
  {
    vid: 7,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TWT-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13',
    },
    migration: false,
    token: tokens.twt,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 259,
    stratAddresses: {
      97: '',
      56: '0x087A5aFA858AF7fC5e1ea6d613b86943A22AA241',
    },
  },
  {
    vid: 8,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'CAKE-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    migration: false,
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 251,
    stratAddresses: {
      97: '',
      56: '0x3df547F2A7fE4B4CbC05cf9abAAd2Ac2bB8048ff',
    },
  },
  {
    vid: 9,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'ADA-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    },
    migration: false,
    token: tokens.ada,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 253,
    stratAddresses: {
      97: '',
      56: '0x98905e8f1FedcF89e69bFB7641F3C14062BbC1b4',
    },
  },
  {
    vid: 10,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TUSD-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0x2e28b9b74d6d99d4697e913b82b41ef1cac51c6c',
    },
    migration: false,
    token: tokens.tusd,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 397,
    stratAddresses: {
      97: '',
      56: '0x1Fe43202d511BE3f5F34f29437ea31382e54bda5',
    },
  },
  {
    vid: 11,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TRX-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x3cd338c3bb249b6b3c55799f85a589febbbff9dd',
    },
    migration: false,
    token: tokens.trx,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 368,
    stratAddresses: {
      97: '',
      56: '0xAB27143864d7Ad7d66186d91B7e29848Bd3Ffe87',
    },
  },
  {
    vid: 12,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'UNI-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x014608e87af97a054c9a49f81e1473076d51d9a3',
    },
    migration: false,
    token: tokens.uni,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 268,
    stratAddresses: {
      97: '',
      56: '0xb79fc83404Af7446E875F097AA98D6A605F09b57',
    },
  },
  {
    vid: 13,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'LINK-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
    },
    migration: false,
    token: tokens.link,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 257,
    stratAddresses: {
      97: '',
      56: '0xf416054AC4FA769A17eC50421553a503e236e768',
    },
  },
  {
    vid: 14,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'XVS-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x7eb5d86fd78f3852a3e0e064f2842d45a3db6ea2',
    },
    migration: false,
    token: tokens.uni,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 260,
    stratAddresses: {
      97: '',
      56: '0xE28Fa263B54E9f5beFB39695Bb728562A3c51237',
    },
  },
  {
    vid: 15,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'UST-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0x05faf555522Fa3F93959F86B41A3808666093210',
    },
    migration: false,
    token: tokens.ust,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 293,
    stratAddresses: {
      97: '',
      56: '0x65e55E7DFE2a7c3eE39C8EDf8e573554BB9cE87B',
    },
  },
  {
    vid: 16,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'WELL-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0x1d94cb25895AbD6ccFeF863c53372bb462AA6b86',
    },
    migration: false,
    token: tokens.well,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 392,
    stratAddresses: {
      97: '',
      56: '0x80E42210727Eb0Fd214dEdbC66c77cD5D495c0Cf',
    },
  },
  {
    vid: 17,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'singleAsset',
    depositTokenSymbol: 'CAKE',
    depositTokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    migration: false,
    token: tokens.cake,
    quoteToken: tokens.cake,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 0,
    stratAddresses: {
      97: '',
      56: '0x8bd237e94ce369f6d78c7bb08b90efc5c6f27a4f',
    },
  },
  {
    vid: 18,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'BUSD-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    migration: false,
    token: tokens.busd,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 252,
    stratAddresses: {
      97: '',
      56: '0xf7947Cb3e30370CC46d643107437018A4276B629',
    },
  },
  {
    vid: 19,
    platform: 'ballena.io',
    type: 'balle',
    depositType: 'LP',
    depositTokenSymbol: 'BALLE-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xa4C9418d4CE79cD2b5d64E08D91B7D00cEC40dC3',
    },
    migration: false,
    token: tokens.balle,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '',
    },
    farmPid: 252,
    stratAddresses: {
      97: '',
      56: '0x7365359652cc0bad7fe8649c6e7f2ea5d159e147',
    },
  },
  {
    vid: 20,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'SKILL-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xC19dfd34D3ba5816dF9CBDaa02D32A9F8dc6F6fC',
    },
    migration: false,
    token: tokens.skill,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 434,
    stratAddresses: {
      97: '',
      56: '0x647f38ABD38a4494C97C4B19A10d0F3502400AF1',
    },
  },
  {
    vid: 21,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'ALPACA-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0x7752e1FA9F3a2e860856458517008558DEb989e3',
    },
    migration: false,
    token: tokens.alpaca,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 362,
    stratAddresses: {
      97: '',
      56: '0x2abf8948Cd3fd821fA9d6936e79120cb17078e2b',
    },
  },
  {
    vid: 22,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'TRX-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146',
    },
    migration: false,
    token: tokens.trx,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 442,
    stratAddresses: {
      97: '',
      56: '0x11c89AB47447bE31c50C998c43c3Ac9De6C565Ac',
    },
  },
  {
    vid: 23,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'AXS-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0xC2d00De94795e60FB76Bc37d899170996cBdA436',
    },
    migration: false,
    token: tokens.axs,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 430,
    stratAddresses: {
      97: '',
      56: '0x4aB2878E1924Dd48bea4252Effa4c0D1AE06c8f7',
    },
  },
  {
    vid: 24,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'ONE-BNB',
    depositTokenAddresses: {
      97: '',
      56: '0x9D2296e2Fe3CdBf2EB3e3e2CA8811BaFA42eeDFF',
    },
    migration: false,
    token: tokens.harmony,
    quoteToken: tokens.wbnb,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 427,
    stratAddresses: {
      97: '',
      56: '0x0d6086c54ae42611fb5386220f75921055466c76',
    },
  },
  {
    vid: 25,
    platform: 'Pancake',
    type: 'autoCompound',
    depositType: 'LP',
    depositTokenSymbol: 'AMPL-BUSD',
    depositTokenAddresses: {
      97: '',
      56: '0x6E98Beb694Ff1CDb1eE130eDD2b21b0298683d58',
    },
    migration: false,
    token: tokens.ampl,
    quoteToken: tokens.busd,
    farmAddresses: {
      97: '',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    farmPid: 410,
    stratAddresses: {
      97: '',
      56: '0x1b837fa472e7d72f8f7e2729da82a1c4c9333575',
    },
  },
];

// We load vaults config based on env for development.
const VaultsConfig = process.env.NODE_ENV === 'development' ? devVaultsConfig : vaultsConfig;
export default VaultsConfig;
