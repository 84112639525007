import BigNumber from 'bignumber.js';

const BSC_BLOCK_TIME = 3;
const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365); // 10512000

export const getBlocksPerYear = () => {
  return BLOCKS_PER_YEAR;
};

export default null;
