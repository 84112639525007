import BigNumber from 'bignumber.js';
import addresses from 'config/constants/contracts';
import tokens from 'config/constants/tokens';
import erc20ABI from 'config/abi/erc20.json';
import multicall from 'utils/multicall';
import { getAddress } from 'utils/addressHelpers';

const fetchCakeBusdPrice = async () => {
  const calls = [
    // Balance of CAKE on the LP contract
    {
      address: getAddress(tokens.cake.address),
      name: 'balanceOf',
      params: [getAddress(addresses.cakeWbnbLP)],
    },
    // Balance of WBNB token on LP contract
    {
      address: getAddress(tokens.wbnb.address),
      name: 'balanceOf',
      params: [getAddress(addresses.cakeWbnbLP)],
    },
    // Balance of WBNB on LP contract
    {
      address: getAddress(tokens.wbnb.address),
      name: 'balanceOf',
      params: [getAddress(addresses.wbnbBusdLP)],
    },
    // Balance of BUSD on LP contract
    {
      address: getAddress(tokens.busd.address),
      name: 'balanceOf',
      params: [getAddress(addresses.wbnbBusdLP)],
    },
  ];
  const [cakeBalanceCakeLP, wbnbBalanceCakeLP, wbnbBalanceBusdLP, busdBalanceBusdLP] = await multicall(erc20ABI, calls);

  const bnbPerCake = new BigNumber(wbnbBalanceCakeLP).div(new BigNumber(cakeBalanceCakeLP));
  const busdPerBnb = new BigNumber(busdBalanceBusdLP).div(new BigNumber(wbnbBalanceBusdLP));
  const cakeBusdPrice = bnbPerCake.times(busdPerBnb).toNumber();

  return cakeBusdPrice;
};

export default fetchCakeBusdPrice;
