/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fetchBalleBusdPrice from 'state/blockchainPrices/fetchBalleBusdPrice';
import fetchWbnbBusdPrice from 'state/blockchainPrices/fetchWbnbBusdPrice';
import fetchCakeBusdPrice from 'state/blockchainPrices/fetchCakeBusdPrice';
import { BlockchainPrices } from '../types';

const initialState: BlockchainPrices = {
  busd: 1,
  balle: 0,
  wbnb: 0,
  cake: 0,
};

export const blockchainPricesSlice = createSlice({
  name: 'BlockchainPrices',
  initialState,
  reducers: {
    setBalleBusdPrice: (state, action: PayloadAction<number>) => {
      state.balle = action.payload;
    },
    setWbnbBusdPrice: (state, action: PayloadAction<number>) => {
      state.wbnb = action.payload;
    },
    setCakeBusdPrice: (state, action: PayloadAction<number>) => {
      state.cake = action.payload;
    },
  },
});

// Actions
export const { setBalleBusdPrice, setWbnbBusdPrice, setCakeBusdPrice } = blockchainPricesSlice.actions;

export const fetchBallePriceAsync = () => async (dispatch) => {
  const balleBusdPrice = await fetchBalleBusdPrice();
  dispatch(setBalleBusdPrice(balleBusdPrice));
};

export const fetchWbnbPriceAsync = () => async (dispatch) => {
  const wbnbBusdPrice = await fetchWbnbBusdPrice();
  dispatch(setWbnbBusdPrice(wbnbBusdPrice));
};

export const fetchCakePriceAsync = () => async (dispatch) => {
  const cakeBusdPrice = await fetchCakeBusdPrice();
  dispatch(setCakeBusdPrice(cakeBusdPrice));
};

export default blockchainPricesSlice.reducer;
